<template>
  <div class="fragment">
    <template
        v-if="$store.getters.getProductsMove.length > 0 && $store.getters.getProductsMoveLoading !== true">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'forbiddenGoods_ID',
            'common_date',
            'product_ProductName',
            'common_user',
            // 'forbiddenGoods_ProductsMoveKeyword',
            // 'common_manage',
            // 'forbiddenGoods_ToBalance',
            // 'forbiddenGoods_Refund',
          ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
<!--            <th></th>-->
            <th>{{ $t('forbiddenGoods_ID.localization_value.value') }}</th>
            <th>{{ $t('common_date.localization_value.value') }}</th>
            <th v-if="isAdmin"> {{ $t('common_user.localization_value.value')}}</th>
            <th>{{ $t('product_ProductName.localization_value.value') }}</th>
<!--            <th>{{ $t('forbiddenGoods_Store.localization_value.value') }}</th>-->
<!--            <th>{{ $t('forbiddenGoods_ProductsMoveKeyword.localization_value.value') }}</th>-->
            <th width="100%" class="hide-1400"></th>
<!--            <th v-if="isAdmin">{{ $t('common_manage.localization_value.value') }}</th>-->
          </tr>
          </thead>
          <tbody>

          <tr
              v-for="(item, index) in $store.getters.getProductsMove" :key="index">
<!--            <td>-->
<!--              <DefaultCheckbox-->
<!--                  class="empty-label"-->
<!--                  :selectedNow="selectedNow"-->
<!--                  :dataValue="item.id"-->
<!--              />-->
<!--            </td>-->
            <td>
              <span class="table-link btn-style"
                    @click="openPopup(item.id)"
              >
                #<ValueHelper
                    :value="item"
                    :deep="'id'"
                />
              </span>
            </td>
            <td>
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </td>
            <td v-if="isAdmin">
              <TableUserColumn
                  v-if="isAdmin"
                  :item="item"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item"
                  :deep="'line.product.name'"
              />
            </td>
<!--            <td>-->
<!--              <ValueHelper-->
<!--                  :value="item"-->
<!--                  :deep="'payment_transaction.shop.shop_name'"-->
<!--              />-->
<!--            </td>-->
<!--            <td>-->
<!--              {{ item.keyword }}-->
<!--            </td>-->
            <td class="hide-1400"></td>
<!--            <td class="p-0">-->

<!--            </td>-->
          </tr>
          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getProductsMove"
                 :key="index">
              <ProductsMoveTableMobile
                  :item="item"
                  @changeInfoPopup="$emit('changeInfoPopup', true, item.payment_transaction.id)"
                  @approve="$emit('approve', item.id)"
                  @refund="$emit('refund', item.id)"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                v-if="$store.getters.getProductsMoveCommonList.next_page_url !== null && $store.getters.getProductsMove.length > 0"
                @click.native="$emit('showMore')"
                v-bind:class="{'disabled-btn' : $store.getters.getNextProductsMovePage}"
                :count="$store.getters.getProductsMoveCommonList.total - $store.getters.getProductsMoveForPage * $store.getters.getProductsMoveCommonList.current_page < $store.getters.getProductsMoveForPage ?
                    $store.getters.getProductsMoveCommonList.total - $store.getters.getProductsMoveForPage * $store.getters.getProductsMoveCommonList.current_page:
                    $store.getters.getProductsMoveForPage"
            />

            <ExportBtn
                class="table-bottom-btn__right"
                @downloadFiles="type => $emit('downloadFiles', type)"
            />
          </div>
        </div>


      </div>
    </template>

    <template
        v-if="$store.getters.getProductsMoveLoading === false && $store.getters.getProductsMove.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>

    <InfoPopup
        v-if="isModalInfoPopup"
        :itemId="idReport"
        @close="isModalInfoPopup = false"
    />
  </div>
</template>

<script>
import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
import InfoPopup from "../../../../popups/InfoPopup/InfoPopup";
// import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
import ProductsMoveTableMobile from "./ProductsMoveTableMobile/ProductsMoveTableMobile";
import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
// import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import {routeFilter} from "../../../../../../../mixins/routeFilterMixins/routeFilter";
import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'

export default {
  name: "ProductsMoveTable",
  components: {
    ExportBtn,
    ValueHelper,
    // DefaultCheckbox,
    TableUserColumn,
    ProductsMoveTableMobile,
    NoResult,
    ShowMore,
    InfoPopup,
    // MainButton,
  },

  mixins: [mixinDetictingMobile, routeFilter],

  props: {
    countFilterParams: Number,
    selectedNow: Boolean,
  },

  data() {
    return {
      isModalInfoPopup: false,
      idReport: null,
    }
  },

  methods: {

    openPopup(id) {
      this.idReport = id
      this.isModalInfoPopup = true
    },

  }
}
</script>

<style scoped lang="scss">
.comment-row {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 115px;
  position: relative;
  display: inline-block;
  white-space: nowrap;
}

@media (max-width: 1400px) {
  .comment-row {
    width: 100px;
  }
}

.table-manage-list .manager-btn {
  width: 200px;
}

@media (max-width: 1400px) {
  .table-manage-list .manager-btn {
    width: 100px;
  }

  .user-field-admin {
    flex-wrap: wrap;
    display: flex;
  }

  .hide-1400 {
    display: none;
  }
}

/*.site-table thead th:first-child,*/
/*.site-table tbody td:first-child{*/
/*padding-right: 0;*/
/*}*/

/*.site-table thead th:nth-child(2),*/
/*.site-table tbody td:nth-child(2){*/
/*padding-left: 0;*/
/*}*/

/*.site-table thead th:last-child,*/
/*.site-table tbody td:last-child{*/
/*padding-right: 0;*/
/*}*/

.small-table th,
.small-table td {
  white-space: nowrap;
}

.size-24 {
  width: 24px;
  height: 24px;
  margin-top: -2px;
}


</style>

<template>
  <modal
      @close="$emit('close')"
      class="transaction-info-modal transaction-info"
  >
    <template slot="header">
      <div v-if="moveLines.length"
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <ValueHelper
            :value="moveLines"
            :deep="'0.product.name'"
        />
      </div>
    </template>
    <template slot="body">
      <div class="transaction-info__content" v-if="loaded">
        <div class="transaction-info__section">
          <div class="transaction-info__row">
            <div class="transaction-info__col w-100">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_date'])"></div>
                {{$t('common_date.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                {{ moveItem.created_at | moment("DD MMM, YYYY") }}
              </div>
            </div>
            <template v-for="(line, index) in moveLines">
              <template v-if="line.calc_quantity_before !== line.quantity">
                <div class="custom-col custom-col--33" :key="index + 'before'">
                  <div class="transaction-info__label"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
<!--                    <div class="admin-edit" @click="editTranslate(['cuspayments_lotInvoiceNumber'])"></div>-->
                    {{$t('inventory_FBM.localization_value.value')}}
                  </div>
                  <div class="transaction-info__txt">
                    <ValueHelper
                        :value="line"
                        :deep="'calc_quantity_before'"
                    />
                  </div>
                </div>
                <div class="custom-col custom-col--33" :key="index + 'after'">
                  <div class="transaction-info__label"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
<!--                    <div class="admin-edit" @click="editTranslate(['cuspayments_trackingNumber'])"></div>-->
                    {{$t('inventory_AfterCount.localization_value.value')}}
                  </div>
                  <div class="transaction-info__txt">
                    <ValueHelper
                        :value="line"
                        :deep="'quantity'"
                    />
                  </div>
                </div>
                <div class="custom-col custom-col--33" :key="index + 'cell'">
                  <div class="transaction-info__label"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
<!--                    <div class="admin-edit" @click="editTranslate(['inventory_Cell'])"></div>-->
                    {{$t('inventory_Cell.localization_value.value')}}
                  </div>
                  <div class="transaction-info__txt">
                    <ValueHelper
                        :value="line"
                        :deep="'cell.combo_name'"
                    />
                  </div>
                </div>
              </template>
            </template>

          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import ValueHelper from "../../../../coreComponents/ValueHelper/ValueHelper";


  export default {
    name: "InfoPopup",
    components: {
      ValueHelper,
      Modal,
    },

    props: {
      itemId: [Number, String]
    },

    data() {
      return {
        loaded: false,
        moveLines: [],
        moveItem: null,

      }
    },

    mounted() {
      if(this.itemId){
        this.$store.dispatch('getProductsMove', this.itemId).then((response) => {
          this.moveItem = this.getRespData(response)
          this.moveLines = this.getRespData(response)?.lines
          this.loaded = true
        })
      }
    },

    methods: {

    }

  }

</script>

<style lang="scss" scoped>
  @media(max-width: 550px){
    .transaction-info__col{
      width: 100%;
    }

    .scoped-btns > button{
      font-size: 14px;
      padding-left: 10px;
      padding-right: 10px;
      width: fit-content;
      margin-bottom: 10px;
    }
  }
</style>

import { render, staticRenderFns } from "./ProductsMoveTable.vue?vue&type=template&id=654de589&scoped=true"
import script from "./ProductsMoveTable.vue?vue&type=script&lang=js"
export * from "./ProductsMoveTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "654de589",
  null
  
)

export default component.exports